<template lang="pug">
	v-container(fill-height align="center"  justify="center")
		v-row( align="center"  justify="center" )
			v-col( cols="12" sm="12" md="6" class="text-center")
				v-alert(type="success" color="blue blue-700" dark dense style="margin-top: -15px;" v-if="message") Te hemos enviado un email para restablecer tu contraseña.
		v-row( align="center"  justify="center" )
			v-col( cols="12" sm="6" lg="5" class="text-center")
				span(class="display-2 mb-10 blue-500--text") Analítica Coppel Omnicanal
				v-spacer
				v-spacer
				v-icon(size="150" color="blue-400" class="mt-10") mdi-chart-bell-curve-cumulative
			v-col( cols="12" sm="6" lg="5")
				v-card( class="elevation-12")
					v-card-text
						v-form
							h2(class="title  font-weight-bold black--text text-center mb-10") Ingresa tu correo para buscar tu cuenta
							
							v-text-field( prepend-icon="mdi-account" placeholder=" " label="Correo electrónico" type="text" v-model="email")

							transition(name="slide-fade" mode="out-in")
								template(v-if="errors")
									ul
										li(v-for="item of errors" class="red--text") <b> {{item}}</b>

					v-card-actions
						v-spacer
						v-btn( class="text-none"
							@click="handleClickRegresar" ) Regresar
						v-btn( color="blue-500" 
							class="white--text text-none"
							:loading="loading"
							:disabled="loading"
							@click="resetPasswordRequest()" ) Enviar
						
</template>
<script>
	import DataLayersMixin from '@/mixins/DataLayersMixin'
	import Users from '../services/Users'
	const users = new Users();

	export default {
		props: {
			routeForm: String
		},
		data () {
			return {
				loading		: false,
				email	: '',
				errors 		: {},
				message: false
			}
		},
		mixins: [DataLayersMixin],
		methods: {
			resetPasswordRequest:function(){

				this.errors = {};

				let  data = {
					email	: this.email,
				};
				this.loading = true;

				// seend request
				users.resetPassword( data )
				.then( (response )=>{
            
                    this.loading = false;
                    this.email = ''
					this.message = true;

					// send datalayer
					this.dataLayerClickBtnsRecuperarContrasena('Enviar');
				})
				.catch(error => {
					var res = error.response.data;
					var err = Object.values(res);
					this.loading = false;
					this.errors = typeof err == 'object' ? err[0] : ['error'];
				})
			},
			handleClickRegresar() {
				this.dataLayerClickBtnsRecuperarContrasena('Regresar');
				this.$router.push({name: 'login'});
			}
		},
		mounted() {
			this.dataLayerLoadedPageRecuperarContra();
		}
	}
</script>